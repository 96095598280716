import { getProgressColorVar, ProgressColor } from '@lyra/core/utils/progress'
import React from 'react'
import { StackProps, useTheme, XStack, YStack } from 'tamagui'

import BodyText from '../BodyText'

type Props = {
  percentComplete: number // 0 to 1
  color?: ProgressColor
  height?: number
  isGlowing?: boolean
  isAnimating?: boolean
  topLeftLabel?: string
  topRightLabel?: string
  bottomLeftLabel?: string
  bottomRightLabel?: string
} & StackProps

const ProgressBar = ({
  height = 6,
  percentComplete,
  color = 'primary',
  isGlowing,
  isAnimating,
  topLeftLabel,
  topRightLabel,
  bottomLeftLabel,
  bottomRightLabel,
  ...stackProps
}: Props) => {
  const progressPercent = Math.max(Math.min(percentComplete, 1), 0) * 100
  const gap = 1.5 * height
  const theme = useTheme()

  const colorKey = theme[getProgressColorVar(color)]?.get()
  const colorCode = theme[getProgressColorVar(color)]?.val

  return (
    <YStack gap="$1.5" {...stackProps}>
      {topLeftLabel || topRightLabel ? (
        <XStack justifyContent="space-between">
          <BodyText color="secondary">{topLeftLabel}</BodyText>
          <BodyText>{topRightLabel}</BodyText>
        </XStack>
      ) : null}
      <XStack height={height} width={`calc(100% - ${height}px)`} gap={gap}>
        {percentComplete > 0 ? (
          <XStack
            backgroundColor={colorKey}
            width={`${progressPercent}%`}
            height="100%"
            enterStyle={{
              width: 0,
            }}
            style={{
              boxShadow: isGlowing ? `0px 0px 10px ${colorCode}` : undefined,
            }}
            animation={isAnimating ? 'level-ease' : undefined}
          >
            <XStack
              height={0}
              width={0}
              borderTopWidth={height}
              borderRightWidth={height}
              borderTopColor={colorKey}
              borderRightColor={percentComplete === 1 ? colorKey : 'transparent'}
              overflow="hidden"
              position="absolute"
              right={-height}
              style={{
                filter: isGlowing ? `drop-shadow(0px 0px 5px ${colorCode})` : undefined,
              }}
            />
          </XStack>
        ) : null}
        <XStack
          backgroundColor="$hairline"
          width={`${100 - progressPercent}%`}
          enterStyle={{
            width: '100%',
          }}
          height="100%"
          animation={isAnimating ? 'level-ease' : undefined}
        >
          {percentComplete < 1 ? (
            <XStack
              backgroundColor="$hairline"
              width={`${100 - progressPercent}%`}
              height="100%"
              enterStyle={{
                width: 0,
              }}
              animation={isAnimating ? 'level-ease' : undefined}
            >
              <XStack
                height={0}
                width={0}
                borderBottomWidth={height}
                borderLeftWidth={height}
                borderBottomColor="$hairline"
                borderLeftColor={percentComplete === 0 ? '$hairline' : 'transparent'}
                overflow="hidden"
                position="absolute"
                left={percentComplete === 0 ? '100%' : -height}
              />
            </XStack>
          ) : null}
        </XStack>
      </XStack>
      {bottomLeftLabel || bottomRightLabel ? (
        <XStack justifyContent="space-between">
          <BodyText color="secondary">{bottomLeftLabel}</BodyText>
          <BodyText>{bottomRightLabel}</BodyText>
        </XStack>
      ) : null}
    </YStack>
  )
}

export default ProgressBar
