'use client'

import BodyText from '@lyra/core/components/BodyText'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Heading from '@lyra/core/components/Heading'
import { ChevronRight } from '@lyra/core/components/Icon'
import Icon from '@lyra/core/components/Icon'
import DRVToken from '@lyra/core/components/Icon/DRVToken'
import Section from '@lyra/core/components/Section'
import formatDate from '@lyra/core/utils/formatDate'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatUSD from '@lyra/core/utils/formatUSD'
import TokenIcon from '@lyra/web/components/common/TokenIcon'
import { getOpReferralBonusTooltipCopy, USDC_REFERRAL_TOOLTIP_COPY } from '@lyra/web/constants/copy'
import { PageId } from '@lyra/web/constants/pages'
import usePoints from '@lyra/web/hooks/usePoints'
import useReferrals from '@lyra/web/hooks/useReferrals'
import { getPagePath } from '@lyra/web/utils/pages'
import { useRouter } from 'next/navigation'
import React from 'react'
import { XStack, YStack } from 'tamagui'

export default function AirdropReferralSection() {
  const { points, epoch } = usePoints()

  const router = useRouter()

  const referralPoints = points?.referral ?? 0

  const { data, isLoading } = useReferrals()

  const referredFees = data?.user?.referredFees ?? 0

  const usdcRewards = data?.user?.usdcRewards ?? 0
  const opRewards = data?.user ? data.user.opRewards + data.user.joinOpRewardsBonus : 0

  return (
    <Section
      isLarge
      hoverStyle={{ backgroundColor: '$hoverBg' }}
      pressStyle={{ backgroundColor: '$pressBg' }}
      cursor="pointer"
      onPress={() => router.push(getPagePath({ page: PageId.Referrals }))}
    >
      <Section.Header
        title={
          <XStack alignItems="center">
            <Heading size="h3">Refer</Heading>
            <XStack marginLeft="$2" zIndex={2}>
              <Icon icon={<DRVToken />} size={30} />
            </XStack>
            <XStack zIndex={1} marginLeft={-12}>
              <TokenIcon symbol="USDC" size={30} />
            </XStack>
            <XStack zIndex={0} marginLeft={-12}>
              <TokenIcon symbol="OP" size={30} />
            </XStack>
          </XStack>
        }
      />
      <Section.XStack alignItems="center" isCompact flexWrap="wrap">
        <YStack flexGrow={1} flexShrink={1}>
          <YStack>
            <BodyText color="secondary">
              {`1. Earn 20% of your referred users' DRV, and 10% of their referred users' DRV.`}
            </BodyText>
            <BodyText color="secondary">
              {`2. Earn 10% of referred users' fees paid in USDC, up to $200k fees paid per user.`}
            </BodyText>
            <BodyText color="secondary">
              {`3. You and your referred users' earn a 50 OP bonus when they pass $100 in fees paid. Subject to availability*.`}
            </BodyText>
          </YStack>
        </YStack>
        <XStack $mobile={{ display: 'none' }} minWidth={18}>
          <Icon icon={<ChevronRight />} size={18} color="secondary" />
        </XStack>
      </Section.XStack>
      <Section.Grid maxColWidth={160} minColWidth={140}>
        <DataWithLabel
          label="Referral Points"
          value={formatNumber(referralPoints) + ' Points'}
          color={referralPoints > 0 ? 'cta' : 'primary'}
        />
        <DataWithLabel
          tooltipTitleSameAsLabel
          tooltipContent={`Total trading fees you have referred since ${formatDate(
            epoch.startTimestamp,
            { skipYear: true }
          )}. Only counts your immediate referrals.`}
          label="Referred Fees"
          value={!isLoading ? formatUSD(referredFees) : '...'}
        />
        <DataWithLabel
          label="USDC Rewards"
          tooltipTitleSameAsLabel
          tooltipContent={USDC_REFERRAL_TOOLTIP_COPY}
          value={!isLoading ? formatNumber(usdcRewards) + ' USDC' : '...'}
        />
        <DataWithLabel
          label="OP Rewards"
          tooltipTitleSameAsLabel
          tooltipContent={getOpReferralBonusTooltipCopy(epoch)}
          value={!isLoading ? formatNumber(opRewards) + ' OP' : '...'}
        />
      </Section.Grid>
      <Section.XStack>
        <BodyText color="secondary">
          *OP bonuses are subject to limited availability. Referrers will earn OP bonuses on a first
          come first serve based on when the referred trader crosses the $100 fees paid milestone.
        </BodyText>
      </Section.XStack>
    </Section>
  )
}
