import formatNumber from './formatNumber'

const TRILLION = Math.pow(10, 12)
const BILLION = Math.pow(10, 9)
const MILLION = Math.pow(10, 6)
const THOUSAND = Math.pow(10, 3)

type FormatNumberOptions = {
  dps?: 0 | 1 | 2
}

export default function formatTruncatedNumber(value: number, options?: FormatNumberOptions) {
  const dps = options?.dps ?? 2
  if (Math.abs(value) >= TRILLION - Math.pow(10, 10)) {
    // trillion
    return formatNumber(value / TRILLION, { minDps: 0, maxDps: dps }) + 't'
  } else if (Math.abs(value) >= BILLION - Math.pow(10, 7)) {
    // billion
    return formatNumber(value / BILLION, { minDps: 0, maxDps: dps }) + 'b'
  } else if (Math.abs(value) >= MILLION - Math.pow(10, 4)) {
    // million
    return formatNumber(value / MILLION, { minDps: 0, maxDps: dps }) + 'm'
  } else if (Math.abs(value) >= THOUSAND - Math.pow(10, 1)) {
    // thousand
    return formatNumber(value / THOUSAND, { minDps: 0, maxDps: dps }) + 'k'
  } else {
    // hundreds
    return formatNumber(value, { minDps: 0, maxDps: dps })
  }
}
