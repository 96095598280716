import Heading from '@lyra/core/components/Heading'
import Responsive from '@lyra/core/components/Responsive'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatTruncatedNumber from '@lyra/core/utils/formatTruncatedNumber'
import useOptimisticPoints from '@lyra/web/hooks/useOptimisticPoints'

const AirdropHeaderPoints = () => {
  const { totalPoints = 0 } = useOptimisticPoints()

  return (
    <Responsive
      mobile={
        <Heading size="h1" color="cta">
          {formatTruncatedNumber(totalPoints)} Points
        </Heading>
      }
      desktop={
        <Heading size="h1" color="cta">
          {formatNumber(totalPoints, { dps: 2 })} Points
        </Heading>
      }
    />
  )
}

export default AirdropHeaderPoints
