import useSWR from 'swr'

import { OpRewardsStats, OpRewardsUser } from '../constants/opRewards'

const fetcher = async (): Promise<{
  user: OpRewardsUser | null | undefined
  stats: OpRewardsStats
}> => {
  const response = await fetch('/api/op-rewards')
  return await response.json()
}

export default function useOpRewards() {
  return useSWR(['OpRewards'], fetcher, { revalidateOnFocus: false })
}
