import formatTruncatedNumber from './formatTruncatedNumber'
import formatUSD from './formatUSD'

type FormatUSDOptions = {
  showSign?: boolean
  dps?: 0 | 1 | 2
}

export default function formatTruncatedUSD(price: number, options?: FormatUSDOptions) {
  if (price < 1000) {
    return formatUSD(price, options)
  }
  const signStr = price < 0 ? '-' : price >= 0 && options?.showSign ? '+' : ''
  const absVal = Math.abs(price)
  return signStr + '$' + formatTruncatedNumber(absVal, { dps: options?.dps })
}
