import { InstrumentType } from '../constants/instruments'
import { OP_REWARDS_ROUNDS, OpRewardsConfig } from '../constants/opRewards'

// always return a round
export const getCurrentOpRewardsRound = (now: Date) => {
  const currRound = OP_REWARDS_ROUNDS.find(
    (round) => now.getTime() >= round.startTimestamp && now.getTime() <= round.endTimestamp
  )
  if (currRound) {
    return currRound
  }
  if (now.getTime() < OP_REWARDS_ROUNDS[0].startTimestamp) {
    // starts before first round
    return OP_REWARDS_ROUNDS[0]
  } else {
    // starts after last round
    return OP_REWARDS_ROUNDS[OP_REWARDS_ROUNDS.length - 1]
  }
}

export const getOpRewardsMultiplier = (
  opRound: OpRewardsConfig,
  instrumentType: InstrumentType,
  isRfq: boolean
) => {
  if (instrumentType === InstrumentType.Perps) {
    return opRound.perpsBoost ?? 1
  }
  if (isRfq) {
    return opRound.rfqBoost ?? 1
  }
  return 1
}
