import formatDate from '@lyra/core/utils/formatDate'

import { OpRewardsConfig } from './opRewards'
import { PointsEpoch } from './points'

export const COPY_AIRDROP_SUBTITLE = `Earn DRV points by trading, depositing collateral and referring friends to the protocol. DRV will be rewarded based on your share of DRV points each round. The DRV airdrop is scheduled for Jan 15 2025.`

export const getOpMultiplierTooltipCopy = (round: OpRewardsConfig) =>
  round.tiers
    ? `Earn ${round.tiers[0].multiplier}x your share of OP rewards if you are in the top ${round.tiers[0].rank} traders based on trading fees paid.`
    : ''

export const getOpRankTooltipCopy = (round: OpRewardsConfig) =>
  round.tiers
    ? `Your trading rank based on total trading fees paid this week. Get into the top ${round.tiers[0].rank} to earn ${round.tiers[0].multiplier}x your share of OP rewards.`
    : ''

export const getOpReferralBonusTooltipCopy = (epoch: PointsEpoch) =>
  `You and your referred users' earn a 50 OP bonus when they pass $100 in fees paid. Only applies to traders referred after ${formatDate(
    epoch.startTimestamp,
    {
      skipYear: true,
    }
  )}. Subject to availability*.`

export const USDC_REFERRAL_TOOLTIP_COPY = `Earn 10% of referred users' fees paid in USDC, up to $200k fees paid per user.`
