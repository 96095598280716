'use client'

import BodyText from '@lyra/core/components/BodyText'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Heading from '@lyra/core/components/Heading'
import Icon, { ChevronRight } from '@lyra/core/components/Icon'
import DRVToken from '@lyra/core/components/Icon/DRVToken'
import OptimismIcon from '@lyra/core/components/Icon/OptimismIcon'
import Section from '@lyra/core/components/Section'
import SpanText from '@lyra/core/components/SpanText'
import formatDateTime from '@lyra/core/utils/formatDateTime'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatTruncatedNumber from '@lyra/core/utils/formatTruncatedNumber'
import formatUSD from '@lyra/core/utils/formatUSD'
import { getOpMultiplierTooltipCopy } from '@lyra/web/constants/copy'
import { PageId } from '@lyra/web/constants/pages'
import { POINTS_PER_FEE_DOLLAR } from '@lyra/web/constants/points'
import useAuth from '@lyra/web/hooks/useAuth'
import useOpRewards from '@lyra/web/hooks/useOpRewards'
import useOrderbookTimestamp from '@lyra/web/hooks/useOrderbookTimestamp'
import usePoints from '@lyra/web/hooks/usePoints'
import { getCurrentOpRewardsRound } from '@lyra/web/utils/opRewards'
import { getPagePath } from '@lyra/web/utils/pages'
import { useRouter } from 'next/navigation'
import React, { useMemo } from 'react'
import { XStack, YStack } from 'tamagui'

export default function AirdropTradingSection() {
  const { points, epoch } = usePoints()
  const router = useRouter()

  const { data, isLoading } = useOpRewards()

  const { isAuthenticated } = useAuth()

  const tradingPoints = points ? points.trading : 0
  const opRewards = data?.user?.opRewards ?? 0
  const opMultiplier = data?.user?.multiplier ?? 1
  const tradingFees = data?.user?.fees ?? 0

  const { getTimestamp } = useOrderbookTimestamp()
  const round = useMemo(() => getCurrentOpRewardsRound(new Date(getTimestamp())), [getTimestamp])

  return (
    <Section
      isLarge
      hoverStyle={{ backgroundColor: '$hoverBg' }}
      pressStyle={{ backgroundColor: '$pressBg' }}
      cursor="pointer"
      onPress={() => router.push(getPagePath({ page: PageId.TradersLeaderboard }))}
      width="100%"
    >
      <Section.Header
        title={
          <XStack alignSelf="center">
            <Heading size="h3">Trade</Heading>
            <XStack marginLeft="$2" zIndex={1}>
              <Icon icon={<DRVToken />} size={30} />
            </XStack>
            <XStack marginLeft={-12}>
              <Icon icon={<OptimismIcon />} size={30} />
            </XStack>
          </XStack>
        }
      />
      <Section.XStack alignItems="center" isCompact flexWrap="wrap">
        <YStack flexGrow={1} flexShrink={1}>
          <YStack>
            <BodyText color="secondary">
              1. Earn {formatTruncatedNumber(POINTS_PER_FEE_DOLLAR)} DRV points for every $1 in
              trading fees.
            </BodyText>
            <BodyText color="secondary">
              2. Earn a share of{' '}
              <SpanText color="red">{formatTruncatedNumber(round.totalOpRewards)} OP</SpanText>{' '}
              every week proportional to trading fees.
            </BodyText>
          </YStack>
        </YStack>
        <XStack $mobile={{ display: 'none' }} minWidth={18}>
          <Icon icon={<ChevronRight />} size={18} color="secondary" />
        </XStack>
      </Section.XStack>
      <Section.Grid maxColWidth={160} minColWidth={140}>
        <DataWithLabel
          label="Trading Points"
          value={formatNumber(tradingPoints) + ' Points'}
          color={tradingPoints > 0 ? 'cta' : 'primary'}
        />
        <DataWithLabel
          label="Points Boost"
          tooltipTitleSameAsLabel
          tooltipContent="Until token launch on Jan 15, a 5x boost is applied to DRV rewards for trading fees paid. Earn 50k points per $1 fees paid."
          value="5x"
          color="cta"
        />
        <DataWithLabel
          label="Weekly Fees"
          tooltipTitleSameAsLabel
          tooltipContent={`Your total trading fees paid this week since ${formatDateTime(
            epoch.startTimestamp,
            { hideYear: true }
          )}. Updates every 15 minutes.`}
          value={formatUSD(tradingFees)}
        />
        {round.tiers ? (
          <DataWithLabel
            label="OP Multiplier"
            tooltipTitleSameAsLabel
            tooltipContent={getOpMultiplierTooltipCopy(round)}
            value={!isLoading ? `${opMultiplier}x` : '...'}
          />
        ) : null}
        <DataWithLabel
          label="Weekly OP Rewards"
          tooltipTitleSameAsLabel
          tooltipContent={`${formatNumber(
            round.totalOpRewards
          )} OP will be distributed to traders this week proportional to trading fees paid.`}
          value={
            isAuthenticated
              ? !isLoading
                ? `${formatNumber(opRewards)} OP`
                : '...'
              : `${formatNumber(round.totalOpRewards)} OP`
          }
        />
      </Section.Grid>
    </Section>
  )
}
