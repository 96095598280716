import useSWR from 'swr'

import { ReferralStats, ReferralUser } from '../constants/referrals'

const fetcher = async (): Promise<{
  user: ReferralUser | null | undefined
  stats: ReferralStats
}> => {
  const response = await fetch('/api/referrals', { cache: 'no-store' })
  return await response.json()
}

export default function useReferrals() {
  return useSWR(['TradingReferrals'], fetcher, { revalidateOnFocus: false })
}
