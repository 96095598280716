'use client'

import Button from '@lyra/core/components/Button'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Heading from '@lyra/core/components/Heading'
import { Twitter } from '@lyra/core/components/Icon'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import CopyButton from '@lyra/web/components/common/CopyButton'
import NextLinkButton from '@lyra/web/components/common/NextLinkButton'
import PageWithBanner from '@lyra/web/components/layout/PageWithBanner'
import { COPY_AIRDROP_SUBTITLE } from '@lyra/web/constants/copy'
import { PageId } from '@lyra/web/constants/pages'
import { MARKET_MAKER_ADDRESSES } from '@lyra/web/constants/walletLists'
import AirdropBonusesSection from '@lyra/web/containers/airdrop/AirdropBonusesSection'
import AirdropDepositSection from '@lyra/web/containers/airdrop/AirdropDepositSection'
import AirdropHeaderPoints from '@lyra/web/containers/airdrop/AirdropHeaderPoints'
import AirdropLevelProgressRow from '@lyra/web/containers/airdrop/AirdropLevelProgressRow'
import AirdropReferralSection from '@lyra/web/containers/airdrop/AirdropReferralSection'
import AirdropTradingSection from '@lyra/web/containers/airdrop/AirdropTradingSection'
import LevelsTooltipContent from '@lyra/web/containers/common/LevelsTooltipContent'
import SignInButton from '@lyra/web/containers/common/SignInButton'
import useAuth from '@lyra/web/hooks/useAuth'
import usePoints from '@lyra/web/hooks/usePoints'
import { getPagePath } from '@lyra/web/utils/pages'
import { formatLevel, getTwitterIntentUrl } from '@lyra/web/utils/points'
import React from 'react'
import { XStack } from 'tamagui'

export default function AirdropPageHelper() {
  const { isBlocked, shareableInviteLink, level, epoch } = usePoints()
  const { user, isAuthenticated } = useAuth()

  const isMarketMaker = user ? MARKET_MAKER_ADDRESSES.has(user.address) : false

  return (
    <PageWithBanner
      bannerTitle={
        isAuthenticated ? (
          <AirdropHeaderPoints />
        ) : (
          <Heading size="h1" color="cta">
            $DRV
          </Heading>
        )
      }
      bannerSubtitle="Deposit, trade and refer friends to earn DRV, OP and USDC"
      bannerBottomRow={isAuthenticated ? <AirdropLevelProgressRow /> : null}
    >
      <Section isLarge noTopBorder width="100%">
        <Section.Header
          title="How To Earn DRV"
          subtitle={COPY_AIRDROP_SUBTITLE}
          rightContent={
            <XStack height="fit-content" $mobile={{ display: 'none' }}>
              <NextLinkButton label="History" href={getPagePath({ page: PageId.Rounds })} />
            </XStack>
          }
        />
        {isAuthenticated ? (
          <Section.XStack flexWrap="wrap">
            <DataWithLabel
              label="Level"
              tooltipContent={level ? <LevelsTooltipContent level={level} epoch={epoch} /> : null}
              value={level ? formatLevel(level) : 'Unknown'}
            />
            {shareableInviteLink ? (
              <DataWithLabel
                label="Referral Link"
                value={shareableInviteLink}
                rightContent={
                  <XStack gap="$1" alignItems="center">
                    <CopyButton size="sm" value={shareableInviteLink} />
                    <Button
                      icon={<Twitter />}
                      size="sm"
                      href={getTwitterIntentUrl(shareableInviteLink)}
                      target="_blank"
                    />
                  </XStack>
                }
              />
            ) : null}
          </Section.XStack>
        ) : (
          <Section.XStack>
            <SignInButton width={170} size="lg" isCta />
          </Section.XStack>
        )}
        {isMarketMaker ? (
          <Section.XStack>
            <Notice
              message="Your wallet has been marked as a market maker. Market makers are not eligible for deposit points or airdrop bonuses."
              width="fit-content"
              status="success"
            />
          </Section.XStack>
        ) : null}
        {isBlocked && user ? (
          <Section.XStack>
            <Notice
              message={`Your wallet with address ${formatTruncatedAddress(
                user.ownerAddress
              )} has been flagged for trading activity that violates our Terms of Use. Your DRV points and additional rewards are blocked until further notice. If you believe is an error, please contact support.`}
              width="fit-content"
              status="warning"
            />
          </Section.XStack>
        ) : null}
      </Section>
      <AirdropBonusesSection />
      <AirdropTradingSection />
      <AirdropDepositSection />
      <AirdropReferralSection />
    </PageWithBanner>
  )
}
