'use client'

import BodyText from '@lyra/core/components/BodyText'
import Card from '@lyra/core/components/Card'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Heading from '@lyra/core/components/Heading'
import Section from '@lyra/core/components/Section'
import formatTruncatedUSD from '@lyra/core/utils/formatTruncatedUSD'
import {
  AIRDROP_BONUS_CONFIGS,
  AirdropBonusId,
  BONUS_MIN_DEPOSIT_VALUE,
  BONUS_MIN_VOLUME,
} from '@lyra/web/constants/points'
import usePointsBonuses from '@lyra/web/hooks/usePointsBonuses'
import Image from 'next/image'
import React from 'react'
import { XStack, YStack } from 'tamagui'

export default function AirdropBonusesSection() {
  const { data: bonuses } = usePointsBonuses()

  return (
    <Section isLarge>
      <Section.Header title="Bonuses" />
      <Section.Grid minColWidth={340} maxColWidth={550}>
        {Object.values(AIRDROP_BONUS_CONFIGS).map(({ id, imageUrl, name, description }) => {
          const bonus = bonuses ? bonuses[id] : null

          return (
            <Card key={id} flexDirection="row" gap="$2" flexGrow={1} flexShrink={1} flexBasis={1}>
              <Image height={120} width={120} src={imageUrl} alt={name} />
              <YStack flexGrow={1} flexShrink={1}>
                <Heading size="h4">{name}</Heading>
                <BodyText color="secondary">{description}</BodyText>
                {bonus ? (
                  <XStack marginTop="$3" gap="$2" flexWrap="wrap">
                    <DataWithLabel
                      label="Status"
                      color={bonus.isComplete ? 'green' : bonus.isEligible ? 'primary' : 'amber'}
                      value={
                        bonus.isComplete
                          ? 'Complete'
                          : bonus.isEligible
                          ? 'Not Complete'
                          : 'Not Eligible'
                      }
                      isSmall
                      flexGrow={1}
                      flexShrink={1}
                      flexBasis={0}
                      minWidth={95}
                      maxWidth={120}
                    />
                    {id !== AirdropBonusId.Loyalty ? (
                      <DataWithLabel
                        label="Deposited"
                        color={
                          bonus.deposited >= BONUS_MIN_DEPOSIT_VALUE && bonus.isEligible
                            ? 'green'
                            : 'primary'
                        }
                        value={`${formatTruncatedUSD(bonus.deposited)} / $10k`}
                        isSmall
                        flexGrow={1}
                        flexShrink={1}
                        flexBasis={0}
                        minWidth={95}
                        maxWidth={120}
                      />
                    ) : null}
                    <DataWithLabel
                      label="Volume"
                      color={
                        bonus.volume >= BONUS_MIN_VOLUME && bonus.isEligible ? 'green' : 'primary'
                      }
                      value={`${formatTruncatedUSD(bonus.volume)} / $1m`}
                      isSmall
                      flexGrow={1}
                      flexShrink={1}
                      flexBasis={0}
                      minWidth={95}
                      maxWidth={120}
                    />
                    {/* <DataWithLabel
                      label="Remaining Spots"
                      value={`${1000 - bonus.numComplete} / 1000`}
                      isSmall
                      flexGrow={1}
                      flexShrink={1}
                      flexBasis={0}
                    /> */}
                  </XStack>
                ) : null}
              </YStack>
            </Card>
          )
        })}
      </Section.Grid>
    </Section>
  )
}
