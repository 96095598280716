import useIsMobile from '@lyra/core/hooks/useIsMobile'
import React from 'react'
import { isServer, StackProps, YStack } from 'tamagui'

type Props = {
  mobile?: React.ReactNode
  desktop?: React.ReactNode
} & Omit<StackProps, 'children'>

// ALways renders desktop + mobile on server
// Conditional render on client
export default function Responsive({ mobile, desktop, ...styleProps }: Props) {
  // Hook will only apply on client render
  const isMobile = useIsMobile()
  return (
    <>
      {(isServer || isMobile) && mobile ? (
        <YStack {...styleProps} $desktop={{ ...styleProps['$desktop'], display: 'none' }}>
          {mobile}
        </YStack>
      ) : null}
      {(isServer || !isMobile) && desktop ? (
        <YStack {...styleProps} $mobile={{ ...styleProps['$mobile'], display: 'none' }}>
          {desktop}
        </YStack>
      ) : null}
    </>
  )
}
