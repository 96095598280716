import BodyText from '@lyra/core/components/BodyText'
import Section from '@lyra/core/components/Section'
import formatNumber from '@lyra/core/utils/formatNumber'
import { LevelConfig, PointsEpoch } from '@lyra/web/constants/points'
import { formatLevel } from '@lyra/web/utils/points'
import { useMemo } from 'react'
import { XStack, YStack } from 'tamagui'

type Props = {
  level: LevelConfig
  epoch: PointsEpoch
}

const COL_WIDTH = 120

export default function LevelsTooltipContent({ level, epoch }: Props) {
  const groupedLevels = useMemo(
    () =>
      Object.values(
        epoch.levels.reduce(
          (dict, level) => ({ ...dict, [level.level]: (dict[level.level] || []).concat(level) }),
          {} as Record<number, LevelConfig[]>
        )
      ).sort((a, b) => a[0].level - b[0].level),
    [epoch]
  )

  return (
    <>
      <XStack alignItems="center">
        <BodyText width={COL_WIDTH} color="secondary">
          Level
        </BodyText>
        <BodyText width={COL_WIDTH} color="secondary" textAlign="right">
          Points
        </BodyText>
      </XStack>
      {groupedLevels.map((configs, i) => {
        const firstLevel = configs[0]
        return (
          <YStack key={firstLevel.level}>
            {i > 0 ? <Section.Separator marginVertical="$1" /> : null}
            <YStack>
              {configs.map((config) => {
                const isMyLevel =
                  level && config.level === level.level && config.sublevel === level.sublevel
                return (
                  <XStack key={config.sublevel}>
                    <BodyText width={COL_WIDTH} color={isMyLevel ? 'cta' : 'primary'}>
                      {formatLevel(config)}
                    </BodyText>
                    <BodyText
                      width={COL_WIDTH}
                      textAlign="right"
                      color={isMyLevel ? 'cta' : 'primary'}
                    >
                      {formatNumber(config.cutoff)}
                    </BodyText>
                  </XStack>
                )
              })}
            </YStack>
          </YStack>
        )
      })}
    </>
  )
}
