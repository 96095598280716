import React from 'react'
import { SVGProps } from 'react'

export default function DRVToken(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="500"
      height="500"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="10" y="10" width="480" height="480" rx="240" fill="#0A0A0A" />
      <rect
        x="10"
        y="10"
        width="480"
        height="480"
        rx="240"
        stroke="url(#paint0_linear_1032_219)"
        strokeWidth="20"
      />
      <path
        d="M407.786 267.157L290.237 89.3723C289.662 88.5089 288.329 88.8724 288.269 89.9024C287.996 94.4008 284.194 118.423 257.113 118.423C235.136 118.423 225.003 114.469 202.36 114.469C194.529 114.469 185.214 118.907 180.322 126.329L171.083 140.309L259.521 274.034H294.115C304.323 274.034 313.85 279.153 319.47 287.665L340.598 319.623H412.542V282.879C412.542 277.305 410.891 271.838 407.802 267.173L407.786 267.157ZM311.321 191.003C297.114 185.247 286.042 160.059 286.042 160.059C314.729 160.059 334.085 194.244 334.085 194.244C334.085 194.244 325.528 196.758 311.321 191.003Z"
        fill="url(#paint1_linear_1032_219)"
      />
      <path
        d="M301.417 387.963H171.509C162.179 387.978 153.47 383.298 148.32 375.513L93.0065 291.861C86.2817 281.698 86.2817 268.49 93.0065 258.327L154.53 165.391L154.56 165.36L301.402 387.948L301.417 387.963Z"
        fill="url(#paint2_linear_1032_219)"
      />
      <path
        d="M407.786 267.158L290.237 89.3732C289.662 88.5099 288.329 88.8734 288.269 89.9033C287.996 94.4017 284.194 118.423 257.113 118.423C235.136 118.423 225.003 114.47 202.36 114.47C194.529 114.47 185.214 118.908 180.322 126.33L171.083 140.31L259.521 274.035H294.115C304.323 274.035 313.85 279.154 319.47 287.666L340.598 319.624H412.542V282.88C412.542 277.306 410.891 271.838 407.802 267.173L407.786 267.158ZM311.321 191.004C297.114 185.248 286.042 160.06 286.042 160.06C314.729 160.06 334.085 194.245 334.085 194.245C334.085 194.245 325.528 196.759 311.321 191.004Z"
        fill="url(#paint3_linear_1032_219)"
      />
      <path
        d="M301.417 387.964H171.509C162.179 387.979 153.47 383.299 148.32 375.514L93.0065 291.862C86.2817 281.699 86.2817 268.491 93.0065 258.328L154.53 165.392L154.56 165.361L301.402 387.949L301.417 387.964Z"
        fill="url(#paint4_linear_1032_219)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1032_219"
          x1="250"
          y1="0"
          x2="250"
          y2="500"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F63E58" />
          <stop offset="1" stopColor="#FCB124" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1032_219"
          x1="291.813"
          y1="319.623"
          x2="291.813"
          y2="88.8887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCB124" />
          <stop offset="1" stopColor="#F63E58" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1032_219"
          x1="194.69"
          y1="387.963"
          x2="194.69"
          y2="165.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCB124" />
          <stop offset="1" stopColor="#F63E58" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1032_219"
          x1="291.813"
          y1="319.624"
          x2="291.813"
          y2="88.8896"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCB124" />
          <stop offset="1" stopColor="#F63E58" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1032_219"
          x1="194.69"
          y1="387.964"
          x2="194.69"
          y2="165.361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCB124" />
          <stop offset="1" stopColor="#F63E58" />
        </linearGradient>
      </defs>
    </svg>
  )
}
