'use client'

import BodyText from '@lyra/core/components/BodyText'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Heading from '@lyra/core/components/Heading'
import Icon, { ChevronRight } from '@lyra/core/components/Icon'
import DRVToken from '@lyra/core/components/Icon/DRVToken'
import EtherFiIcon from '@lyra/core/components/Icon/EtherFiIcon'
import Section from '@lyra/core/components/Section'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatUSD from '@lyra/core/utils/formatUSD'
import { PageId } from '@lyra/web/constants/pages'
import { Points } from '@lyra/web/constants/points'
import { yieldTokenConfigs } from '@lyra/web/constants/yield'
import useOptimisticPoints from '@lyra/web/hooks/useOptimisticPoints'
import useTotalAccountValue from '@lyra/web/hooks/useTotalAccountValue'
import { getPagePath } from '@lyra/web/utils/pages'
import { useRouter } from 'next/navigation'
import React, { useMemo } from 'react'
import { XStack, YStack } from 'tamagui'

export default function AirdropDepositSection() {
  const { data } = useTotalAccountValue()

  const { points } = useOptimisticPoints()
  const yieldPoints = useMemo(() => {
    if (!points) {
      return 0
    }
    const uniquePointsIds: (keyof Points)[] = Array.from(
      new Set([
        'account_value',
        ...Object.values(yieldTokenConfigs).map((config) => config.pointsId),
      ])
    )
    return uniquePointsIds.reduce((sum, pointsId) => sum + (points[pointsId] ?? 0), 0)
  }, [points])

  const router = useRouter()

  return (
    <Section
      isLarge
      hoverStyle={{ backgroundColor: '$hoverBg' }}
      pressStyle={{ backgroundColor: '$pressBg' }}
      cursor="pointer"
      onPress={() => router.push(getPagePath({ page: PageId.Earn }))}
    >
      <Section.Header
        title={
          <XStack alignItems="center">
            <Heading size="h3">Deposit</Heading>
            <XStack marginLeft="$2" zIndex={1}>
              <Icon icon={<DRVToken />} size={30} />
            </XStack>
            <XStack marginLeft={-12}>
              <Icon icon={<EtherFiIcon />} size={30} />
            </XStack>
          </XStack>
        }
      />
      <Section.XStack alignItems="center" isCompact flexWrap="wrap">
        <YStack flexGrow={1} flexShrink={1}>
          <BodyText color="secondary">
            1. Earn 1 DRV point per day for every $1 deposited into vaults or trading accounts.
          </BodyText>
          <BodyText color="secondary">
            2. Earn boosted points from partner protocols like EtherFi, Swell, Kelp, Ethena and
            Lombard.
          </BodyText>
        </YStack>
        <XStack $mobile={{ display: 'none' }} minWidth={18}>
          <Icon icon={<ChevronRight />} size={18} color="secondary" />
        </XStack>
      </Section.XStack>
      <Section.Grid maxColWidth={160} minColWidth={140}>
        <DataWithLabel
          label="Deposit Points"
          value={formatNumber(yieldPoints) + ' Points'}
          color={yieldPoints > 0 ? 'cta' : 'primary'}
        />
        <DataWithLabel
          label="Trading Collateral"
          value={formatUSD(data?.tradingAccountValue ?? 0)}
        />
        <DataWithLabel label="Vault Deposits" value={formatUSD(data?.yieldValue ?? 0)} />
      </Section.Grid>
    </Section>
  )
}
