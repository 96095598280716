import { SECONDS_IN_HOUR } from '@lyra/core/constants/time'
import { useEffect, useMemo, useState } from 'react'

import {
  ACCOUNT_VALUE_POINTS_RATE_HOURLY,
  Points,
  YIELD_POINTS_RATE_HOURLY,
} from '../constants/points'
import { MARKET_MAKER_ADDRESSES } from '../constants/walletLists'
import { YieldPointsId } from '../constants/yield'
import { getTotalPoints } from '../utils/points'
import { getYieldTokenConfig } from '../utils/yield'
import useAuth from './useAuth'
import useOrderbookTimestamp from './useOrderbookTimestamp'
import usePoints from './usePoints'
import useSubaccount from './useSubaccount'
import useYieldPositions from './useYieldPositions'

// 100ms updates
const POLL_INTERVAL_MS = 100

type OptimisticPointsData = {
  totalPoints?: number | undefined
  points?: Points | undefined
}

const getOptimisticPoints = (
  timestamp: number,
  lastUpdateTimestamp: number,
  pointsRatePerInterval: number,
  vaultPointsRatePerInterval: Partial<Record<YieldPointsId, number>>,
  initPoints: Points
): Points => {
  // Calc how many 100ms intervals since last update
  // - Floored at 0
  const numIntervals = Math.max(Math.floor((timestamp - lastUpdateTimestamp) / POLL_INTERVAL_MS), 0)
  // 1 point per $100 per hour
  const newAccountValuePoints =
    +(initPoints.account_value ?? '0') + numIntervals * pointsRatePerInterval

  const newVaultPoints = Object.entries(vaultPointsRatePerInterval).reduce(
    (dict, [key, pointsRatePerInterval]) => {
      return {
        ...dict,
        [key]: +initPoints[key as YieldPointsId] + numIntervals * pointsRatePerInterval,
      }
    },
    {} as Partial<Record<YieldPointsId, number>>
  )

  return {
    ...initPoints,
    ...newVaultPoints,
    account_value: newAccountValuePoints,
  }
}

// 1 point per $1 per day
const YIELD_POINTS_RATE_PER_POLL_INTERVAL =
  (YIELD_POINTS_RATE_HOURLY / SECONDS_IN_HOUR / 1_000) * POLL_INTERVAL_MS

export default function useOptimisticPoints(): OptimisticPointsData {
  const { totalAccountValue } = useSubaccount()

  const { data: yieldPositions } = useYieldPositions()
  const { user } = useAuth()
  const address = user?.address

  const { points, lastUpdateTimestamp, isBlocked } = usePoints()

  const isMarketMaker = address && MARKET_MAKER_ADDRESSES.has(address)

  const accountPointsRatePerInterval = useMemo(
    // Account Value rate per ms * 100ms inetervals
    () =>
      !isMarketMaker && !isBlocked // skip account value for MMs
        ? ((totalAccountValue * ACCOUNT_VALUE_POINTS_RATE_HOURLY) / (SECONDS_IN_HOUR * 1_000)) *
          POLL_INTERVAL_MS
        : 0,
    [totalAccountValue, isMarketMaker, isBlocked]
  )

  const vaultPointsRatePerInterval: Partial<Record<YieldPointsId, number>> = useMemo(() => {
    return yieldPositions && !isMarketMaker && !isBlocked
      ? Object.values(yieldPositions).reduce(
          (dict, position) => {
            const value = position.value
            const config = getYieldTokenConfig(position.id)
            const pointsId = config.pointsId
            const pointsRatePerInterval =
              value * YIELD_POINTS_RATE_PER_POLL_INTERVAL * config.pointsMultiplier

            if (!dict[pointsId]) {
              dict[pointsId] = 0
            }

            dict[pointsId] += pointsRatePerInterval
            return dict
          },
          {} as Partial<Record<YieldPointsId, number>>
        )
      : {}
  }, [yieldPositions, isMarketMaker, isBlocked])

  const { getTimestamp: getOrderbookTimestamp } = useOrderbookTimestamp()

  const [optimisticPoints, setOptimisticPoints] = useState(
    points
      ? isMarketMaker || isBlocked
        ? points // no optimistic updates for MMs
        : getOptimisticPoints(
            getOrderbookTimestamp(),
            lastUpdateTimestamp,
            accountPointsRatePerInterval,
            vaultPointsRatePerInterval,
            points
          )
      : undefined
  )

  // optimistically tick account per value points every 100ms
  useEffect(() => {
    if (!points || isBlocked || isMarketMaker) {
      return
    }
    const tickAccountValuePoints = () => {
      setOptimisticPoints(
        getOptimisticPoints(
          getOrderbookTimestamp(),
          lastUpdateTimestamp,
          accountPointsRatePerInterval,
          vaultPointsRatePerInterval,
          points
        )
      )
    }
    tickAccountValuePoints()
    const i = setInterval(() => tickAccountValuePoints(), POLL_INTERVAL_MS)
    return () => {
      clearInterval(i)
    }
  }, [
    points,
    lastUpdateTimestamp,
    accountPointsRatePerInterval,
    vaultPointsRatePerInterval,
    getOrderbookTimestamp,
    isBlocked,
    isMarketMaker,
  ])

  return useMemo(
    () => ({
      totalPoints: optimisticPoints ? getTotalPoints(optimisticPoints) : undefined,
      points: optimisticPoints,
    }),
    [optimisticPoints]
  )
}
