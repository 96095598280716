import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { useMemo } from 'react'

import { TokenId } from '../constants/tokens'
import { LyraFundingBalances } from '../constants/wallet'
import { getCollateralForToken, getTokenDecimals } from '../utils/tokens'
import { coerce } from '../utils/types'
import useCollaterals from './useCollaterals'
import useTransaction from './useTransaction'

export default function useLyraFundingBalances() {
  const { balances, mutateBalances } = useTransaction()
  const collaterals = useCollaterals()

  const fundingBalances = useMemo(
    () =>
      Object.entries(balances).reduce((dict, [key, balanceBn]) => {
        const tokenId = coerce(TokenId, key)
        if (!tokenId) {
          return dict
        }
        const collateral = collaterals[getCollateralForToken(tokenId)]
        const decimals = getTokenDecimals(tokenId)
        const balance = bigNumberToNumberUNSAFE(balanceBn, decimals)
        const spotPrice = collateral.spotPrice

        return {
          ...dict,
          [tokenId]: {
            balance,
            balanceBn,
            spotPrice,
            value: balance * spotPrice,
          },
        }
      }, {} as LyraFundingBalances),
    [balances, collaterals]
  )
  return {
    fundingBalances,
    mutate: mutateBalances,
  }
}
