import BodyText from '@lyra/core/components/BodyText'
import formatNumber from '@lyra/core/utils/formatNumber'
import useOptimisticPoints from '@lyra/web/hooks/useOptimisticPoints'
import usePoints from '@lyra/web/hooks/usePoints'
import { formatLevel, getPointsToNextLevel } from '@lyra/web/utils/points'
import { useMemo } from 'react'

export default function AirdropPointsToNextLevelText() {
  const { epoch } = usePoints()
  const { totalPoints = 0 } = useOptimisticPoints()

  const { pointsToNextLevel = 0, nextLevel } = useMemo(() => {
    return getPointsToNextLevel(epoch, totalPoints)
  }, [epoch, totalPoints])

  if (!nextLevel) {
    return null
  }

  return (
    <BodyText color="secondary">
      {formatNumber(pointsToNextLevel, { dps: 2 })} points to {formatLevel(nextLevel)}
    </BodyText>
  )
}
